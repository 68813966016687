export function debounce(fn, delay = 100) {
  // 1.定义一个定时器, 保存上一次的定时器
  let timer = null;

  // 2.真正执行的函数
  const _debounce = function () {
    // 取消上一次的定时器
    if (timer) clearTimeout(timer);
    // 延迟执行
    timer = setTimeout(() => {
      // 外部传入的真正要执行的函数
      fn();
    }, delay);
  };

  return _debounce;
}

export function throttle(fn, time = 100) {
  //3. 通过闭包保存一个 "节流阀" 默认为false
  let temp = false;
  return function () {
    //8.触发事件被调用 判断"节流阀" 是否为true  如果为true就直接trurn出去不做任何操作
    if (temp) {
      return;
    } else {
      //4. 如果节流阀为false  立即将节流阀设置为true
      temp = true; //节流阀设置为true
      //5.  开启定时器
      setTimeout(() => {
        //6. 将外部传入的函数的执行放在setTimeout中
        fn.apply(this, arguments);
        //7. 最后在setTimeout执行完毕后再把标记'节流阀'为false(关键)  表示可以执行下一次循环了。当定时器没有执行的时候标记永远是true，在开头被return掉
        temp = false;
      }, time);
    }
  };
}

export function DeepClone(source) {
  // 判断目标是数组还是对象
  const targetObj = source.constructor === Array ? [] : {};
  for (let key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      //   如果是对象就递归
      if (source[key] && typeof source[key] === "object") {
        targetObj[key] = source[key].constructor === Array ? [] : {};
        targetObj[key] = DeepClone(source[key]);
      } else {
        targetObj[key] = source[key];
      }
    }
  }
  return targetObj;
}

// 处理字符串中的数字变色
export function handleStr(strText, color = "#F93232") {
  var arr = strText.match(/\d+(\.\d+)?/g);
  for (let i = 0; i < arr.length; i++) {
    var replaceString = '<span style="color: red;font-weight:bold">' + arr[i] + "</span>";
    strText = strText.replace(RegExp(arr[i], "g"), replaceString);
  }
  strText = strText.replace(RegExp("red", "g"), color);
  return strText;
}

// 获取字符串宽度
export function getTextWidth(str) {
  const dom = document.createElement("span");
  dom.style.display = "inline-block";
  dom.textContent = str;
  document.body.appendChild(dom);
  const width = dom.clientWidth;
  document.body.removeChild(dom);
  return width;
}
// 手机号校验
export function isValidPhoneNumber(phoneNumber) {
  return /^1[3-9]\d{9}$/.test(phoneNumber);
}
